@font-face {
  font-family: "LamaSans_Black";
  src: url("LamaSans-Black.woff2") format("woff2");
  font-weight: bolder;
  font-style: 800;
}
@font-face {
  font-family: "LamaSans_Bold";
  src: url("LamaSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: 700;
}
@font-face {
  font-family: "LamaSans_SemiBold";
  src: url("LamaSans-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: 600;
}
@font-face {
  font-family: "LamaSans_Medium";
  src: url("LamaSans-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: 500;
}
@font-face {
  font-family: "LamaSans_Regular";
  src: url("LamaSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: 400;
}
@font-face {
  font-family: "LamaSans_Light";
  src: url("LamaSans-Light.woff2") format("woff2");
  font-weight: lighter;
  font-style: 300;
}
@font-face {
  font-family: "LamaSans_Thin";
  src: url("LamaSans-Thin.woff2") format("woff2");
  font-weight: lighter;
  font-style: 200;
}
