@import url("./variables.scss");
@import url("../assets/font/stylesheet.css");

// colors
$orange: #fd5200;
$black: #2a292e;

// Font
$LamaSans_Black: "LamaSans_Black";
$LamaSans_Bold: "LamaSans_Bold";
$LamaSans_SemiBold: "LamaSans_SemiBold";
$LamaSans_Medium: "LamaSans_Medium";
$LamaSans_Regular: "LamaSans_Regular";
$LamaSans_Light: "LamaSans_Light";
$LamaSans_Thin: "LamaSans_Thin";

// Navbar & global
#top {
  position: absolute;
  top: 0;
  height: 0;
  width: 0;
}
header {
  width: 100%;
  max-width: 1440px;
  margin: 26px auto;
  padding: 0 25px;
  z-index: 999;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 600px) {
    margin: 35px auto 20px auto;
    padding: 0 15px;
  }

  nav {
    width: 100%;
    max-width: 1440px - 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 33px;
    position: relative;
    // margin: 0 20px;
    padding: 14px 33px;
    background-color: white;
    border: 1px solid #eaeaea;
    z-index: 999;
    // left: 50%;
    // top: 40px;
    // transform: translate(-50%, 0);

    a > img {
      height: 37px;
      &:last-child {
        display: none;
      }
      &:first-child {
        display: block;
      }
    }
    @media screen and (max-width: 550px) {
      a > img {
        &:last-child {
          display: block;
        }
        &:first-child {
          display: none;
        }
      }
      .contact {
        img {
          display: block !important;
        }
      }
    }

    ul {
      display: flex;
      list-style: none;
      align-items: center;
      gap: 25px;
      margin: 0;
      padding: 0;

      li {
        a {
          font-family: $LamaSans_Medium;
          font-size: 14px;
          color: $black;
        }
        .contact {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          height: 33px;
          color: white;
          padding: 0 30px;
          background-color: $orange;
          font-family: $LamaSans_SemiBold;
          transition: all 200ms ease;
          position: relative;
          overflow: hidden;

          img {
            width: 11px;
          }

          &:after {
            content: "";
            position: absolute;
            background-color: rgba(255, 255, 255, 0.196);
            height: 0px;
            width: 0px;
            border-radius: 50%;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%);
            transition: all 500ms ease-in;
          }

          &:hover {
            &:after {
              height: 400px;
              width: 400px;
            }
          }
        }
        > img {
          display: none;
          cursor: pointer;
        }
        @media screen and (max-width: 1080px) {
          display: none;
          &:last-child {
            display: flex;
            gap: 15px;
            > img {
              display: block;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    nav {
      padding: 0 5px;
      border: none;
      background-color: transparent;
    }
  }
  .back-to-t {
    position: fixed;
    top: calc(100vh - 110px);
    right: 0;
    right: 42px;
    background-color: red;

    z-index: 98;
    width: 34px;
    height: 34px;
    border: none;
    border-radius: 50%;
    border: 1px solid $orange;
    background-color: rgba(255, 255, 255, 0.651);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 400ms ease-out;
    &:hover {
      background-color: $orange;
    }
    img {
      transform: rotate(90deg);
      height: auto;
      width: 15px;
      transition: all 400ms ease-out;
    }
    @media screen and (max-width: 600px) {
      top: calc(100vh - 125px);
    }
  }
  .back-to-b {
    img {
      transform: rotate(-90deg);
    }
  }
  .menu-mobile {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: -35px;
    left: 0;
    background-color: white;
    z-index: 99;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 50px 20px 20px 20px;

    transition: all 600ms cubic-bezier(0.9, 0, 0.32, 1.01);

    ul {
      list-style: none;
      height: inherit;
      max-height: 270px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin: 50px 0 0 0;
      padding: 0;
      li {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        a {
          font-family: $LamaSans_Medium;
          font-size: 16px;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
        }

        &:nth-child(even) {
          border-top: 0.5px solid #eaeaea;
          border-bottom: 0.5px solid #eaeaea;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      hr {
        border: none;
        width: 100%;
      }
    }

    .map {
      width: 100%;
      padding: 55px;
      background-color: $orange;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 20px;

      * {
        color: white;
      }

      *::selection {
        color: $orange;
        background-color: $black;
      }

      .content {
        height: fit-content;

        h2 {
          font-size: 72px;
          text-transform: uppercase;
          font-family: $LamaSans_Bold;
          margin: 0 0 20px 0;
          width: 520px;
        }

        > div {
          > div {
            display: flex;
            gap: 20px;
            > p {
              font-size: 14px;
              width: 400px;
              margin: 0 5px 15px 0;
            }

            > div {
              gap: 8px;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }

      .map-container {
        width: 610px;
        height: 285px;
        background-color: $black;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      @media screen and (max-width: 1150px) {
        flex-direction: column;
        .map-container {
          width: 100%;
        }
        .content {
          p {
            width: auto !important;
          }
        }
      }
      @media screen and (max-width: 750px) {
        .content {
          width: 100%;
        }
        .content h2 {
          font-size: 44px;
          text-align: center;
          width: auto !important;
        }
        .content p {
          width: auto !important;
        }
      }
      @media screen and (max-width: 600px) {
        padding: 20px;
        // .content {
        //   > div > div {
        //     gap: 0;
        //     justify-content: space-between;
        //   }
        // }
      }
      @media screen and (max-width: 432px) {
        .content {
          a {
            font-size: 13px;
          }
        }
        .content h2 {
          font-size: 11vw;
        }
      }
    }
  }
  .close-menu {
    left: 100vw;
  }
}
.hero {
  margin-top: 132px;
  height: clamp(400px, calc(100vh - 122px), 780px);

  @media screen and (max-width: 900px) {
    height: fit-content;
  }
  @media screen and (max-width: 600px) {
    margin-top: 37px + 50px;
  }

  .container {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: center;

    @media screen and (max-width: 600px) {
      padding: 20px;
    }
    .title {
      h1 {
        margin: 0;
        font-size: 44px;
        font-family: $LamaSans_Bold;
      }
      p {
        margin-top: 7px;
        margin-bottom: 18px;
        padding-left: 47px;
        color: $orange;
        position: relative;
        max-width: 850px;

        &:after {
          content: "";
          position: absolute;
          width: 40px;
          height: 0;
          border-top: 1px solid $orange;
          left: 0;
          top: 10px;
        }
      }
      @media screen and (max-width: 600px) {
        h1 {
          font-size: 32px;
        }
        p {
          font-size: 12px;
          padding-left: 0;
          &:after {
            display: none;
          }
        }
      }
      @media screen and (max-width: 390px) {
        h1 {
          font-size: 30px;
        }
      }
    }

    .content {
      width: 100%;
      display: flex;
      height: clamp(300px, 100% - 115px, 100vh - 115px);
      gap: 15px;
      .content-img {
        max-width: 55%;
        overflow: hidden;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }

        // Swiper--------
        .swiper {
          position: relative;
          &:first-child {
            height: 100%;
            .swiper-button-prev,
            .swiper-button-next {
              display: none;
            }
          }
          &:nth-child(2) {
            height: 180px;
            margin-left: 0;
            position: relative;
            left: calc(100% + 15px);
            max-width: calc(100% - 12px);
            top: -180px;
            .swiper-wrapper {
              .swiper-slide {
                margin-right: 15px;
              }
              .swiper-slide,
              img {
                height: 180px;
                width: auto;
              }
            }
          }
        }
        .swiper-btn {
          z-index: 99;
          transform: translate(-25%, 50%);
          bottom: 90px;
          left: 100%;
          position: absolute;
          width: 34px;
          height: 34px;
          border: none;
          border-radius: 50%;
          background-color: $black;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: auto;
            width: 15px;
          }
        }
      }
      .content-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 45%;

        .content-p {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: calc(180px + 15px);

          span {
            color: $orange;
            font-family: $LamaSans_SemiBold;
          }
          strong {
            font-family: $LamaSans_SemiBold;
          }

          p {
            margin: clamp(5px, 10px, 15px) 0;
          }
        }
        .small-img {
          display: flex;
          position: relative;
          gap: 15px;
          height: clamp(0px, 180px, 40%);
          // bottom: 0;

          img {
            height: 100%;
            cursor: pointer;
          }

          button {
            top: 50%;
            transform: translateY(-50%);
            left: -25px;
            position: absolute;
            width: 34px;
            height: 34px;
            border: none;
            border-radius: 50%;
            background-color: $black;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 15px;
            }
          }
        }
      }
      @media screen and (max-width: 900px) {
        flex-direction: column;
        height: fit-content;
        .content-img {
          height: 100%;
          width: calc(100% + 40px);
          max-width: calc(100% + 40px);
          max-height: 320px;
          transform: translateX(-20px);

          .swiper-slide {
            height: 300px;
          }
          .swiper {
            &:nth-child(2) {
              height: 100px;
              top: -100px;
              left: calc(100% + 8px);
              .swiper-slide {
                margin-right: 8px;
              }
              img {
                max-width: 140px;
              }
            }
          }
          .swiper-btn {
            transform: translate(-35%, 50%);
            bottom: 70px;
            height: 30px;
            width: 30px;
            img {
              width: 12px;
            }
          }
        }
        .content-container {
          width: 100%;
          .content-p {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
.service {
  width: 100%;

  .container {
    width: 100%;
    max-width: 1440px;
    padding: 20px 60px;
    display: flex;
    gap: 25px;
    margin: 60px auto;
    // flex-wrap: wrap;

    .title {
      margin: 0 0 30px 0;

      h2 {
        margin: 0;
        font-size: 44px;
        line-height: 35px;
        font-family: $LamaSans_Bold;
      }
      span {
        color: $orange;
      }
    }
    @media screen and (max-width: 600px) {
      margin: 30px auto;
      padding: 20px;
      .title {
        margin-bottom: 0;
        h2 {
          font-size: 32px;
        }
      }
    }

    .content {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      // flex-wrap: wrap;
      width: 100%;

      @media screen and (max-width: 600px) {
        justify-content: left;
      }

      .item {
        border: 1px solid #eaeaea;
        min-height: 265px;
        max-width: 333px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        background-color: white;

        &:after {
          content: "";
          position: absolute;
          bottom: -1px;
          right: -1px;
          background-color: $orange;
          height: 9px;
          width: 9px;
        }

        .item-title {
          position: relative;
          span {
            left: 0;
            bottom: -5px;
            color: $orange;
            position: absolute;
            font-size: 80px;
            opacity: 0.1;
            font-family: $LamaSans_Medium;
          }
          h3 {
            font-family: $LamaSans_Bold;
            font-size: 20px;
            margin: 0 0 10px 0;
          }
        }

        p {
          font-size: 16px;
          margin: 0 0 10px 0;
        }
      }
    }
    @media screen and (max-width: 1320px) {
      flex-wrap: wrap;
      .content {
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
      .item:nth-child(2) {
        transform: translateX(0);
        margin: auto 0 auto auto;
      }
    }
    @media screen and (max-width: 600px) {
      .item {
        min-height: 245px !important;
      }
    }
  }
}

.homepage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: clip;
  position: relative;

  .content-img {
    overflow: visible !important;
  }
  @media screen and (max-width: 900px) {
    .hero .container .content .content-img {
      width: 100%;
      max-width: 100%;
      transform: translateX(-20px);
    }
  }

  main {
    display: flex;
    flex-direction: column;

    .engagement {
      width: 100%;

      .container {
        width: 100%;
        max-width: 1440px;
        padding: 20px 60px;
        display: flex;
        flex-direction: column;
        margin: 50px auto;

        .title {
          margin: 0 0 30px 0;

          h2 {
            margin: 0;
            font-size: 44px;
            font-family: $LamaSans_Bold;
          }
          span {
            color: $orange;
          }
        }

        @media screen and (max-width: 600px) {
          padding: 20px;
          margin: 30px auto;
          .title h2 {
            font-size: 32px;
          }
        }

        .content {
          display: flex;
          gap: 15px;

          .item {
            border: 1px solid #eaeaea;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            position: relative;
            background-color: white;

            &:after {
              content: "";
              position: absolute;
              bottom: -1px;
              right: -1px;
              background-color: $orange;
              height: 9px;
              width: 9px;
            }

            .item-title {
              position: relative;
              img {
                margin-top: 30px;
                margin-bottom: 30px;
                @media screen and (max-width: 600px) {
                  margin-top: 20px;
                  margin-bottom: 25px;
                }
              }
              span {
                left: 0;
                bottom: 0;
                color: $orange;
                position: absolute;
                font-size: 80px;
                opacity: 0.1;
                font-family: $LamaSans_Medium;
              }
              h3 {
                font-family: $LamaSans_Bold;
                font-size: 20px;
                margin: 0 0 10px 0;
              }
            }

            p {
              font-size: 16px;
              margin: 0 0 10px 0;
            }
          }

          @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: center;
            .item {
              max-width: 400px;
            }
          }
        }
      }
    }

    .projects {
      width: 100%;

      .container {
        width: 100%;
        max-width: 1920px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        margin: 50px auto;
        gap: 10px;

        .item {
          height: 475px;
          max-height: 80vh;
          width: 50%;
          position: relative;
          padding: 60px 90px;
          display: flex;
          align-items: flex-end;
          overflow: hidden;

          .item-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            // justify-content: center;
            // align-items: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              transition: all 400ms ease-out;
            }
          }
          &:hover .item-img img {
            width: 102%;
            height: 102%;
            object-position: center;
          }

          .item-content {
            h2 {
              color: white;
              margin: 0 0 20px 0;
              font-size: 44px;
              font-family: $LamaSans_Bold;
              span {
                color: $orange;
              }
            }

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              height: 48px;
              color: white;
              padding: 0 30px;
              border: none;
              background-color: $orange;
              font-size: 20px;
              font-family: $LamaSans_SemiBold;

              position: relative;
              overflow: hidden;
              &:after {
                content: "";
                position: absolute;
                background-color: rgba(255, 255, 255, 0.196);
                height: 0px;
                width: 0px;
                border-radius: 50%;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 50%);
                transition: all 500ms ease-in;
              }

              &:hover {
                &:after {
                  height: 400px;
                  width: 400px;
                }
              }
            }
          }
        }

        @media screen and (max-width: 1080px) {
          .item {
            padding: 40px 50px;
            .item-content {
              h2 {
                font-size: 32px;
              }
            }
          }
        }
        @media screen and (max-width: 850px) {
          flex-direction: column;
          .item {
            width: 100%;
          }
        }
        @media screen and (max-width: 600px) {
          .item {
            max-height: 300px;
            padding: 30px 20px;
          }
        }
      }
    }

    .residences {
      width: 100%;

      .container {
        width: 100%;
        max-width: 1440px;
        padding: 20px 60px;
        display: flex;
        flex-direction: column;
        margin: 50px auto;

        .title {
          margin: 0 0 30px 0;
          display: flex;
          justify-content: space-between;

          h2 {
            margin: 0;
            font-size: 44px;
            font-family: $LamaSans_Bold;
          }
          span {
            color: $orange;
          }
          .btns {
            display: flex;
            gap: 10px;

            button {
              height: 34px;
              width: 34px;
              border: none;
              border-radius: 50%;
              background-color: $black;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                pointer-events: none;
                width: 15px;
              }

              &:first-child {
                img {
                  transform: rotate(180deg);
                }
              }
            }

            @media screen and (max-width: 470px) {
              display: none;
              // align-items: center;
              // button {
              //   height: 30px;
              //   width: 30px;
              // }
            }
          }
        }

        @media screen and (max-width: 600px) {
          margin: 30px auto;
          padding: 20px;
          .title {
            h2 {
              font-size: 32px;
            }
          }
        }
        .content {
          display: flex;
          gap: 15px;
          width: 100vw;
          left: (calc(-60px - (100vw - 1440px) * 0.5 + 8px));
          position: relative;
          @media screen and (max-width: 1330px) {
            left: -55px;
          }
          @media screen and (max-width: 600px) {
            left: -20px;
          }

          .item {
            height: 530px;
            width: 400px;
            position: relative;
            padding: 35px 25px;
            display: flex;
            align-items: flex-end;
            margin: 0 20px 0 0;
            cursor: pointer;
            overflow: hidden;

            .item-img {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: -1;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all 400ms ease-out;
                filter: brightness(0.7);
              }
            }

            &:hover img {
              // height: 600px;
              // width: 470px;
              transform: scale(1.03);
              // width: 103%;
            }

            .item-content {
              p {
                color: white;
                font-size: 20px;
                font-family: $LamaSans_SemiBold;
                margin: 0;
              }
            }

            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              background-color: $orange;
              height: 6px;
              width: 65px;
            }
            @media screen and (max-width: 600px) {
              width: calc(100vw - 50px);
              margin: 0 10px 0 10px;
            }
            @media screen and (max-width: 500px) {
              width: calc(100vw - 50px);
              margin: 0 5px 0 5px;
            }
          }
          // Swiper
          .swiper-slide {
            width: auto !important;
          }
          .swiper-wrapper .swiper-slide:first-child .item {
            margin-left: calc(-1 * (-60px - (100vw - 1440px) * 0.5 + 8px));
            @media screen and (max-width: 1330px) {
              margin-left: 60px;
            }
            @media screen and (max-width: 600px) {
              margin-left: 20px;
            }
          }
          .swiper-wrapper .swiper-slide:last-child .item {
            margin-right: calc(-1 * (-60px - (100vw - 1440px) * 0.5 - 8px));
            @media screen and (max-width: 1330px) {
              margin-right: 70px;
            }
            @media screen and (max-width: 600px) {
              margin-right: 20px;
            }
            @media screen and (max-width: 500px) {
              margin-right: 20px;
            }
          }
          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
          .swiper-pagination {
            display: none;
          }
          // Swiper-----------/
        }
      }
    }

    .bestproject {
      width: 100%;

      .container {
        width: 100%;
        max-width: 1440px;
        padding: 20px 60px;
        display: flex;
        flex-direction: column;
        margin: 60px auto;

        .title {
          margin: 0 0 30px 0;

          h2 {
            margin: 0;
            font-size: 44px;
            font-family: $LamaSans_Bold;
          }
          span {
            color: $orange;
          }
          small {
            color: $orange;
            font-size: 20px;
          }
        }

        .content {
          display: flex;
          gap: 15px;
          align-items: center;

          .content-container {
            width: 50%;
            min-width: 45%;
            height: fit-content;

            p {
              max-width: 500px;
              padding: 10px 0;
              span {
                color: $orange;
              }
            }

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              height: 48px;
              color: white;
              padding: 0 30px;
              border: none;
              background-color: $orange;
              font-size: 20px;
              font-family: $LamaSans_SemiBold;
              margin-top: 50px;

              position: relative;
              overflow: hidden;
              &:after {
                content: "";
                position: absolute;
                background-color: rgba(255, 255, 255, 0.196);
                height: 0px;
                width: 0px;
                border-radius: 50%;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 50%);
                transition: all 500ms ease-in;
              }

              &:hover {
                &:after {
                  height: 400px;
                  width: 400px;
                }
              }
            }
          }

          .content-slider {
            position: relative;
            min-width: calc(50% + (100vw - 1440px) * 0.5 + 110px);
            @media screen and (max-width: 1440px) {
              min-width: calc(50% + 110px);
            }
            @media screen and (max-width: 600px) {
              min-width: calc(50% + 40px);
            }

            .btns {
              display: flex;
              position: absolute;
              top: 50%;
              z-index: 2;
              left: 0;
              transform: translate(-25px, -50%);
              width: calc(100% - 20px);
              justify-content: space-between;

              button {
                height: 34px;
                width: 34px;
                border: none;
                border-radius: 50%;
                background-color: $black;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  pointer-events: none;
                  width: 15px;
                }

                &:first-child {
                  img {
                    transform: rotate(180deg);
                  }
                }
              }
            }
            .content-slider-container {
              img {
                margin-left: 15px !important;
                height: clamp(390px, 80vh, 250px);
              }

              // Swiper
              .swiper-slide {
                width: fit-content !important;
                // pointer-events: none !important;
              }
              > .swiper {
                margin-left: -15px !important;
              }
              .swiper-button-next,
              .swiper-button-prev {
                display: none !important;
              }
              .swiper-pagination {
                display: none;
              }
            }
          }
        }

        @media screen and (max-width: 600px) {
          padding: 20px;
          margin: 30px auto;
          .title {
            h2 {
              font-size: 32px;
            }
          }
          .content {
            flex-direction: column-reverse;
            .content-slider {
              width: 100%;
              .btns {
                width: calc(100% + 10px);
                button:first-child {
                  opacity: 0;
                  pointer-events: none;
                }
              }
            }
            .content-container {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
  //slider
  .slider{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.613);
    z-index: 999;
    display: flex;
    align-items: center;
  }
  
  .sliderWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sliderImg{
    width: 90%;
    height: 90vh;
    @media screen and (max-width: 600px) {
      width: 150%;
      height: 60vh;
    }
  }
  
  .close{
    position: absolute;
    top: 10px;
    right: 28px;
    font-size: 30px;
    color: lightgray;
    cursor: pointer;
  }
  
  .arrow{
    margin: 25px;
    font-size: 25px;
    color: rgb(252, 82, 4);
    cursor: pointer;
    z-index: 9999;
  }


.border-1,
.border-2 {
  height: 100vh;
  max-height: 100vh;
  // max-width: 100%;
  position: absolute;
  z-index: -999;
  top: -40px;
  border-left: 1px solid #eaeaea;
  display: none;
}
.brd-cnt {
  @media screen and (max-width: 800px) {
    display: none;
  }
  position: absolute;
  background-color: red;
  z-index: -999;
  .border-1 {
    position: fixed;
    left: 30px;
  }
  .border-2 {
    left: 60%;
    top: 130vh;
  }
}

footer {
  width: 100%;
  color: white;
  margin-top: 200px;
  background-color: $black;
  @media screen and (max-width: 600px) {
    // margin-top: 250px;
  }

  .container {
    width: 100%;
    max-width: 1440px;
    padding: 0 25px 10px 25px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    a {
      color: white;
    }
    @media screen and (max-width: 600px) {
      padding: 20px;
    }
    .map {
      width: 100%;
      padding: 55px;
      background-color: $orange;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-top: -70px;
      margin-bottom: 5px;
      gap: 20px;

      *::selection {
        color: $orange;
        background-color: $black;
      }

      .content {
        height: fit-content;

        h2 {
          font-size: 72px;
          text-transform: uppercase;
          font-family: $LamaSans_Bold;
          margin: 0 0 20px 0;
          width: 520px;
        }

        > div {
          > p {
            font-size: 14px;
            width: 400px;
            margin: 0 5px 15px 0;
          }
          > div {
            display: flex;
            gap: 20px;

            > div {
              gap: 8px;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }

      .map-container {
        width: 610px;
        height: 285px;
        background-color: $black;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      @media screen and (max-width: 1150px) {
        flex-direction: column;
        .map-container {
          width: 100%;
        }
        .content {
          p {
            width: auto !important;
          }
        }
      }
      @media screen and (max-width: 750px) {
        .content {
          width: 100%;
        }
        .content h2 {
          font-size: 44px;
          text-align: center;
          width: auto !important;
        }
        .content p {
          width: auto !important;
        }
      }
      @media screen and (max-width: 600px) {
        padding: 20px;
        // .content {
        //   > div > div {
        // gap: 0;
        // justify-content: space-between;
        // }
        // }
      }
      @media screen and (max-width: 432px) {
        .content {
          a {
            font-size: 13px;
          }
        }
      }
      @media screen and (max-width: 400px) {
        .content {
          a {
            font-size: 13px;
          }
        }
        .content h2 {
          font-size: 11vw;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      padding: 0 35px;

      nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;

        .reseaux {
          h4 {
            font-size: 16px;
            font-family: $LamaSans_Medium;
            font-weight: normal;
            margin: 0;
          }

          div {
            gap: 5;
            display: flex;
            margin-top: 5px;
            justify-content: space-between;
          }
          a {
            pointer-events: none;
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          width: 320px;
          margin: 0;
          padding: 0;
          justify-content: space-between;

          li {
            text-transform: capitalize;
            margin: 0 0 8px 0;
          }
        }
      }

      .sub-footer {
        display: flex;
        border-top: 1px solid #444;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: #909090;
          font-family: $LamaSans_Medium;
          margin-top: 7px;
          font-size: 12px;
        }

        a {
          display: flex;
          align-items: baseline;
          gap: 5px;
        }

        > div {
          display: flex;
          align-items: baseline;
          gap: 5px;
        }
      }

      @media screen and (max-width: 1000px) {
        nav {
          flex-wrap: wrap;
          gap: 30px;
          ul {
            width: 300px;
          }
        }
      }
      @media screen and (max-width: 600px) {
        padding: 0;
        .sub-footer {
          border: none;
        }
      }
    }
  }
}

.disable {
  cursor: default;
  pointer-events: none;
  opacity: 0.2;
}

// --------------AyaBrique-----------
.aya-brique {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: clip;
  position: relative;

  // Hero
  .container .content .content-container .content-p {
    padding-bottom: 30px;
    justify-content: flex-start;
    // align-items: flex-end;
  }

  // Products
  .products {
    width: 100%;

    .container {
      width: 100%;
      max-width: 1440px;
      padding: 20px 60px;
      display: flex;
      flex-direction: column;
      margin: 50px auto;

      .title {
        margin: 0 0 30px 0;

        h2 {
          margin: 0;
          font-size: 44px;
          font-family: $LamaSans_Bold;
        }
        span {
          color: $orange;
        }
      }

      @media screen and (max-width: 600px) {
        margin: 30px auto;
        padding: 20px;
        .title h2 {
          font-size: 32px;
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        gap: 120px;
        width: 100%;
        @media screen and (max-width: 1080px) {
          gap: 20px;
          flex-wrap: wrap;
        }
        .subtitle {
          display: flex;
          align-items: center;
          h3 {
            color: $orange;
            font-family: $LamaSans_Bold;
            width: 150px;
            font-size: 35px;
            position: relative;
            hr {
              position: absolute;
              bottom: 15px;
              left: 110px;
              margin: 0;
              border: none;
              border-top: 1px solid $orange;
              width: 64px;
            }
          }
        }

        .item-container {
          width: inherit;
          display: flex;
          justify-content: space-between;
          gap: 15px;
          .item {
            width: 177px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            img {
              width: 100%;
              transition: all 600ms ease;
              background-color: transparent;

              // &:hover {
              // border-radius: 6px;
              // background-color: #90909027;
              // }
            }
            h4 {
              margin: 10px 0 0 0;
              font-size: 20px;
              font-family: $LamaSans_Medium;
            }
          }
          @media screen and (max-width: 1080px) {
            justify-content: space-between;
            flex-wrap: wrap;
          }
          @media screen and (max-width: 600px) {
            justify-content: space-evenly;
            flex-wrap: wrap;
            gap: 15px 5px;
            .item:nth-child(even) {
              margin-top: 40px;
            }
            .item:nth-child(odd) {
              margin-bottom: 40px;
            }
          }
          @media screen and (max-width: 400px) {
            .item {
              width: 130px;
            }
          }
        }

        @media screen and (max-width: 600px) {
          .subtitle {
            h3 {
              font-size: 24px;
              margin: 0;
            }
            hr {
              width: 100px;
            }
          }
        }
      }
    }
  }

  // Album
  .album {
    width: 100%;
    
    .container {
      width: 100%;
      max-width: 1440px;
      padding: 20px 60px;
      display: flex;
      gap: 25px;
      margin: 60px auto 20px auto;
      flex-wrap: wrap;

      .title {
        margin: 0 0 30px 0;

        h2 {
          margin: 0;
          font-size: 44px;
          font-family: $LamaSans_Bold;
        }
        span {
          color: $orange;
        }
      }
      @media screen and (max-width: 600px) {
        margin: 30px auto;
        padding: 20px;
        .title {
          margin-bottom: 0;
          h2 {
            font-size: 32px;
          }
        }
      }

      .content {
        width: 100%;
        display: flex;
        position: relative;

        .img-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 25px;

          > div {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            grid-gap: 25px;
            div {
              overflow: hidden;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: all 500ms ease-out;

              &:hover {
                transform: scale(1.025);
              }
            }
          }
          > div:nth-child(odd) {
            div:nth-child(1) {
              grid-column-start: 1;
              grid-column-end: 6;
            }
            div:nth-child(2) {
              grid-column-start: 6;
              grid-column-end: -1;
            }
          }
          > div:nth-child(even) {
            div:nth-child(1) {
              grid-column-start: 1;
              grid-column-end: 5;
            }
            div:nth-child(2) {
              grid-column-start: 5;
              grid-column-end: -1;
            }
          }
        }

        .end-hide {
          position: absolute;
          background-image: linear-gradient(
            to top,
            white 10%,
            rgba(255, 255, 255, 0.7) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          bottom: -5px;
          width: 100%;
          height: 290px;
        }

        @media screen and (max-width: 600px) {
          justify-content: left;
          .img-container {
            gap: 20px;
            > div {
              display: flex;
              flex-direction: column;
              gap: 20px;

              div:nth-child(even) {
                transform: translateX(-20px);
                width: 80%;
              }
              div:nth-child(odd) {
                transform: translateX(20px);
                width: 100%;
                margin-left: auto;
              }
            }
          }
          .end-hide {
            width: 100vw;
            left: -20px;
          }
        }
      }
    }
  }

  footer {
    margin-top: 0;
  }
}

// --------------Yamani-----------
.yamani {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: clip;
  position: relative;

  // Hero
  .container .content .content-container .content-p {
    padding-bottom: 30px;
    justify-content: flex-end;
    // align-items: flex-end;
  }

  // Services
  .service {
    .container {
      flex-wrap: nowrap;
    }
    .container .content {
      flex-wrap: nowrap;
      width: 100%;
      @media screen and (max-width: 1270px) {
        width: 100%;
        justify-content: space-around;
      }

      .item {
        position: relative;
        //width: clamp(330px, 450px, 500px) !important;
        width: inherit !important;
        max-width: none;
        width: 100%;

        &:after {
          content: "";
          position: absolute;
          bottom: -1px;
          right: -1px;
          background-color: $orange;
          height: 9px;
          width: 9px;
        }

        .item-title {
          position: relative;
          img {
            margin-top: 42px;
            margin-bottom: 42px;
          }
          span {
            left: 0;
            bottom: 0;
            color: $orange;
            position: absolute;
            font-size: 80px;
            opacity: 0.1;
            font-family: $LamaSans_Medium;
          }
          h3 {
            font-family: $LamaSans_Bold;
            font-size: 20px;
            margin: 0 0 10px 0;
          }
        }

        p {
          font-size: 16px;
          margin: 0 0 10px 0;
        }
      }
    }

    @media screen and (max-width: 1180px) {
      .container {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 1180px) {
      .container {
        .content {
          flex-wrap: wrap;
          .item {
            max-width: 330px;
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      .item {
        max-width: 400px;
      }
    }
  }
  // Products
  .products {
    width: 100%;

    .product-box {
      position: absolute;
      display: flex;
      margin-left: 20px;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: $orange;
      border: 1px solid $black;
      border-radius: 0 5px 5px 5px;
      font-family: $LamaSans_Bold;
      padding: 2px 8px;
      font-size: 14px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 200ms ease;
      z-index: 999;
    }

    .container {
      width: 100%;
      max-width: 1440px;
      padding: 20px 60px;
      display: flex;
      flex-direction: column;
      margin: 50px auto;

      .title {
        margin: 0 0 30px 0;

        h2 {
          margin: 0;
          font-size: 44px;
          font-family: $LamaSans_Bold;
        }
        span {
          color: $orange;
        }
      }

      @media screen and (max-width: 600px) {
        margin: 30px auto;
        padding: 20px;
        .title h2 {
          font-size: 32px;
        }
      }

      .content {
        width: 100%;
        position: relative;

        .img-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          > div {
            flex: 0 32%;
            margin-bottom: 2%;
            height: 315px;
            // position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              transition: all 400ms ease-out;
              pointer-events: none;
            }
            .product-name {
              display: none;
            }
            &:hover {
              .product-name {
                opacity: 1;
                visibility: visible;
              }
              img {
                transform: scale(1.04);
              }
            }
          }

          .disable-hover {
            pointer-events: none;
          }

          @media screen and (max-width: 775px) {
            padding: 0 30px;
            > div {
              flex: auto;
              height: 230px;
            }
          }
        }

        .end-hide {
          position: absolute;
          background-image: linear-gradient(
            to top,
            white 10%,
            rgba(255, 255, 255, 0.7) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          bottom: -5px;
          width: 100%;
          height: 290px;
          font-size: 28px;
          display: flex;
          padding-top: 60px;
          justify-content: center;
          align-items: center;
          color: rgba(142, 142, 142, 0.336);
          font-family: $LamaSans_Bold;
          text-align: center;
        }
      }
    }
  }

  // Album
  .album {
    width: 100%;

    .container {
      width: 100%;
      max-width: 1440px;
      padding: 20px 60px;
      display: flex;
      gap: 25px;
      margin: 60px auto;
      flex-wrap: wrap;

      .title {
        margin: 0 0 30px 0;

        h2 {
          margin: 0;
          font-size: 44px;
          font-family: $LamaSans_Bold;
        }
        span {
          color: $orange;
        }
      }
      @media screen and (max-width: 600px) {
        margin: 30px auto;
        padding: 20px;
        .title {
          margin-bottom: 0;
          h2 {
            font-size: 32px;
          }
        }
      }

      .content {
        width: 100%;
        display: flex;
        position: relative;

        .img-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 25px;

          > div {
            gap: 25px;
            display: flex;

            img {
              flex: auto;
              width: max-content;
            }
          }
        }

        .end-hide {
          position: absolute;
          background-image: linear-gradient(
            to top,
            white 10%,
            rgba(255, 255, 255, 0.7) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          bottom: -5px;
          width: 100%;
          height: 290px;
        }

        @media screen and (max-width: 600px) {
          justify-content: left;
        }
      }
    }
  }

  footer {
    margin-top: 0;
  }
}

// --------------Project Page---------------
.project-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: clip;
  position: relative;

  // Hero
  .hero .container .title p {
    padding-left: 0;
    &:after {
      display: none;
    }
  }
  .container .content .content-container .content-p {
    padding-bottom: 30px;
    justify-content: flex-start;
    font-size: 18px;
  }

  // Products
  .caracteristiques {
    width: 100%;

    .container {
      width: 100%;
      max-width: 1440px;
      padding: 20px 60px;
      display: flex;
      flex-direction: column;
      margin: 50px auto;

      .title {
        margin: 0 0 30px 0;

        h2 {
          margin: 0;
          font-size: 44px;
          font-family: $LamaSans_Bold;
        }
        span {
          color: $orange;
        }
      }

      @media screen and (max-width: 600px) {
        margin: 30px auto;
        padding: 20px;
        .title h2 {
          font-size: 32px;
        }
      }

      .content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 40px;

        .item {
          &:nth-child(odd) {
            width: calc(55% - 20px);
          }
          &:nth-child(even) {
            width: calc(45% - 20px);
          }
          margin-top: 50px;

          .item-title {
            position: relative;
            span {
              left: 0;
              bottom: -20px;
              color: $orange;
              position: absolute;
              font-size: 80px;
              opacity: 0.25;
              font-family: $LamaSans_Medium;
            }
            h3 {
              font-family: $LamaSans_Bold;
              font-size: 20px;
              margin: 0 0 10px 0;
            }
          }

          ul {
            list-style-image: url("../../public/icons/marker.svg");

            li {
              font-size: 16px;
              font-family: $LamaSans_Medium;
              line-height: 18px;
            }
          }

          &:first-child {
            @media screen and (max-width: 489px) {
              .item-title span {
                bottom: 5px;
              }
            }
          }
        }

        @media screen and (max-width: 980px) {
          flex-direction: column;

          .item {
            width: fit-content !important;
          }
        }
      }
    }
  }

  // Album
  .album {
    width: 100%;

    .container {
      width: 100%;
      max-width: 1440px;
      padding: 20px 60px;
      display: flex;
      gap: 25px;
      margin: 60px auto;
      flex-wrap: wrap;

      .title {
        margin: 0 0 30px 0;

        h2 {
          margin: 0;
          font-size: 44px;
          font-family: $LamaSans_Bold;
        }
        span {
          color: $orange;
        }
      }
      @media screen and (max-width: 600px) {
        margin: 30px auto;
        padding: 20px;
        .title {
          margin-bottom: 0;
          h2 {
            font-size: 32px;
          }
        }
      }
      .content {
        width: 100%;
        display: flex;
        position: relative;

        .img-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 25px;

          > div {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            grid-gap: 25px;
            div {
              overflow: hidden;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: all 600ms ease-out;

              &:hover {
                transform: scale(1.04);
              }
            }
          }
          > div:nth-child(odd) {
            div:nth-child(1) {
              grid-column-start: 1;
              grid-column-end: 6;
            }
            div:nth-child(2) {
              grid-column-start: 6;
              grid-column-end: -1;
            }
          }
          > div:nth-child(even) {
            div:nth-child(1) {
              grid-column-start: 1;
              grid-column-end: 5;
            }
            div:nth-child(2) {
              grid-column-start: 5;
              grid-column-end: -1;
            }
          }
        }

        .end-hide {
          position: absolute;
          background-image: linear-gradient(
            to top,
            white 10%,
            rgba(255, 255, 255, 0.7) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          bottom: -5px;
          width: 100%;
          height: 290px;
        }

        @media screen and (max-width: 600px) {
          justify-content: left;
          .img-container {
            gap: 20px;
            > div {
              display: flex;
              flex-direction: column;
              gap: 20px;

              div:nth-child(even) {
                transform: translateX(-20px);
                width: 80%;
              }
              div:nth-child(odd) {
                transform: translateX(20px);
                width: 100%;
                margin-left: auto;
              }
            }
          }
          .end-hide {
            width: 100vw;
            left: -20px;
          }
        }
      }
      .btns-project {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 15px;
        a {
          display: flex;
          align-items: baseline;
          gap: 10px;
          justify-content: center;

          span {
            font-size: 30px;
            font-family: $LamaSans_SemiBold;
          }
        }

        .btn-prev {
          color: #696969;
        }
        .btn-next {
          svg {
            * {
              fill: $orange !important;
            }
            transform: rotate(180deg);
          }
        }

        @media screen and (max-width: 790px) {
          a {
            span {
              font-size: 24px;
            }
            svg {
              height: 18px;
            }
          }
        }
        @media screen and (max-width: 620px) {
          a {
            gap: 5px;
            span {
              font-size: 18px;
            }
            svg {
              height: 15px;
            }
          }
        }
        @media screen and (max-width: 450px) {
          a {
            gap: 0px;
            span {
              font-size: 14px;
            }
            svg {
              height: 10px;
            }
          }
        }
      }
    }
  }

  footer {
    margin-top: 0;
    .map {
      display: none !important;
    }
  }
}

// --------------Hero2---------------
.homepage2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  header {
    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    padding: 0 25px;
  }

  .hero2 {
    height: clamp(400px, calc(100vh - 122px), calc(100vh - 122px));
    // max-height: 850px;
    // min-height: 680px;

    .container {
      width: 100%;
      height: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 20px 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // justify-content: center;

      .title {
        h1 {
          margin: 0;
          font-size: 44px;
          font-family: $LamaSans_Bold;
        }
        p {
          margin-top: 7px;
          margin-bottom: 18px;
          padding-left: 47px;
          color: $orange;
          position: relative;
          max-width: 850px;

          &:after {
            content: "";
            position: absolute;
            width: 40px;
            height: 0;
            border-top: 1px solid $orange;
            left: 0;
            top: 10px;
          }
        }
      }

      .content {
        width: 100%;
        display: flex;
        height: clamp(300px, 100% - 115px, 100vh - 115px);
        gap: 15px;

        .content-img {
          // width: 50%;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }

          // Swiper-----------
          width: 100%;
          overflow: visible;
          display: flex;
          gap: 15px;
          align-items: flex-end;
          button {
            top: 50%;
            transform: translateY(-50%);
            left: -25px;
            position: absolute;
            width: 34px;
            height: 34px;
            border: none;
            border-radius: 50%;
            background-color: $black;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 15px;
            }
          }
          > .swiper {
            &:first-child {
              width: 50%;
              min-width: 55%;
              height: 100%;
              margin: 0;
              position: relative;
            }
            &:last-child {
              position: absolute;
              right: -0.7%;
              width: calc(50% - 70px);

              .swiper-slide {
                height: 180px !important;
                width: fit-content !important;
              }
              .swiper-wrapper {
                gap: 15px !important;
                img {
                  cursor: pointer;
                  margin-right: 10px !important;
                  opacity: 0.5;
                }
                .swiper-slide-thumb-active img {
                  opacity: 1 !important;
                }
              }
            }
            .swiper-button-prev {
              display: none;
            }
            .swiper-button-next {
              top: inherit !important;
              bottom: 0 !important;
              transform: translateY(-50%);
              right: 0;
              position: absolute;
              width: 34px;
              height: 34px;
              border: none;
              border-radius: 50%;
              background-color: $black;
              display: flex;
              justify-content: center;
              align-items: center;

              &:after {
                font-size: 14px;
              }
            }
          }
        }
        .content-container {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 45%;

          .content-p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            // height: calc(100% - 15px - 180px);

            p {
              margin: clamp(5px, 10px, 15px) 0;
            }
          }
          .small-img {
            display: flex;
            position: relative;
            gap: 15px;
            height: clamp(0px, 180px, 40%);
            // bottom: 0;

            img {
              height: 100%;
              cursor: pointer;
            }

            button {
              top: 50%;
              transform: translateY(-50%);
              left: -25px;
              position: absolute;
              width: 34px;
              height: 34px;
              border: none;
              border-radius: 50%;
              background-color: $black;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 15px;
              }
            }
          }
        }
      }
    }
  }
}

// Cursor
.cursor {
  position: absolute;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 5002;
  transition-property: background-color, height, width !important;
  transition: 250ms ease;

  width: 0;
  height: 0;
  background-color: rgba($color: $orange, $alpha: 5);
  border: 0 $black solid;

  svg {
    position: relative;
    top: -10px;
    left: -5px;
  }
}
.cursor-active {
  // border: 1px $orange solid;
  // width: 50px;
  // height: 50px;
  // background-color: rgba($black, 0.05);
  // backdrop-filter: mixin-exists($name: dx);
}

@media screen and (max-width: 800px) {
  .cursor {
    display: none;
  }
}

// Loading screen
.loading-screen {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: endLoad 0.5s forwards 8.5s;

  svg {
    animation: fade_loading 4s forwards ease 5.5s;
  }

  .cls-1 {
    fill: #2a292e;
  }
  .cls-2 {
    fill: #fd5200;
  }
  @keyframes endLoad {
    to {
      visibility: hidden;
      opacity: 0;
    }
  }
  @keyframes fade_loading {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    80% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
}
.hidden {
  // opacity: 0 !important;
  // visibility: hidden !important;
  animation: none;
  animation: hide_loading_sc 0.5s forwards ease-out;
}

@keyframes hide_loading_sc {
  0% {
    // clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  100% {
    // clip-path: polygon(0 0, 0 0, 100% 100%, 100% 100%);
    filter: blur(15px);
    transform: scale(5);
    opacity: 0.5;
    visibility: hidden;
  }
}
