// colors
$orange: #fd5200;
$black: #2a292e;

html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  color: $black;
  overflow-x: hidden;
  font-family: "LamaSans_Regular";

  *{
    scroll-margin-top: 100px;
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  *::selection {
    color: white;
    background-color: $orange;
  }
  
  button,
  a {
    color: $black;
    font-family: "LamaSans_Regular";
    text-decoration: none;
    cursor: pointer;
  }

  input {
    font-family: "LamaSans_Regular";
    color: $black;
  }
}

// ::-webkit-scrollbar,
// ::-webkit-scrollbar-corner {
//   background-color: auto;
//   background-color: #1d1b1b;
// }
// ::-webkit-scrollbar {
//   border-left: 1px solid hsla(0, 0%, 100%, 0.125);
//   height: 6px;
//   width: 6px;
// }
// ::-webkit-scrollbar,
// ::-webkit-scrollbar-corner {
//   background-color: auto;
//   background-color: $black;
// }
// ::-webkit-scrollbar-thumb {
//   background: rgba($black, 0.2);
//   background: $blue;
//   border: 2px auto;
//   border-radius: 10px;
//   padding-right: 5px;
// }
